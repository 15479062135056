import React, { useState } from 'react';
import './insights.scss';
import { Avatar, Button, Skeleton } from '@fluentui/react-northstar';
import MultiSegmentRangeSlider from '../../Shared/uicomponents/MultiSegmentRangeSlider';
import HSCProgress from './hscProgress';
function InsightsCard({ key, teamView, fetchData, forDialog = false, isNotManager = false, ...data }) {
    const ListViewIndicator = ({ type, label = 'Activity' }) => {
        return (
            <div className='col-auto px-2 d-flex align-items-center'>
                {type && <div className={`list-view-indicator ${type}`} title={`${type} zone`}></div>}
                <div className='list-view-label px-2' title={label === 'Messages' ? 'Chats & Messages' : label}>{label}</div>
            </div>
        )
    }

    const WellbeingPill = ({ type, label, val }) => {
        return (
            <div className={`col-auto px-0 m-1 py-1 rounded-pill border d-flex align-items-center wi-${val}`}>
                {type && <div className={`list-view-indicator ${type}`} title={`${type} zone`}></div>}
                <div className='list-view-label-cst px-2' title={label ? `Wellbeing score- ${val}` : "NA"}>{label}</div>
            </div>
        )
    }

    const TeamViewIndicator = ({ type, value = '!' }) => {
        //debugger
        return (
            <div className='col-auto pl-2 pr-1 d-flex align-items-center'>
                {type && <div className={`detail-view-indicator ${type}`} title={`${type} zone`}></div>}
                <div className='detail-view-label px-2' title={type + ' People'}>{type} - {value}</div>
            </div>
        )
    }

    const InsightCardHeader = (
        { userName = 'User name', userEmail = 'user.name@company.com', userImage, userDesignation = 'no data', vibe = 'Neutral', reportee, reporteeGUID, fetchData }
    ) => {
        //debugger
        return (
            <div className='row insights-card-header align-items-center'>

                <div className=''>
                    <Avatar
                        name={userName}
                        size="large"
                        image={userImage}
                    />
                </div>
                <div className='col px-2 labels'>
                    <div className='name-label'>{userName}</div>
                    <div className='designation-label'>{userDesignation}</div>
                    {(teamView) && <div className='org-email'>{userEmail}</div>}
                </div>
                {(!teamView) ?
                    (<div className={`vibe-indicator align-self-end ${(!teamView) ? '' : 'mb-2'}`}>
                        <div className='vibe-indicator-emoji' >
                            {vibe === 'positive' ? <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Smiling%20Face%20with%20Halo.png" alt="Neutral Face" width="38" height="38" />
                                : (vibe === 'negative' ? <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Worried%20Face.png" alt="Worried Face" width="38" height="38" />
                                    : <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Neutral%20Face.png" alt="Neutral Face" width="38" height="38" />
                                )}
                        </div>
                        <div className='vibe-indicator-text bg-primary text-right'>
                            {vibe === 'positive' ? 'Positive' : (vibe === 'negative' ? 'Negative' : 'Neutral')}
                        </div>
                    </div>) : <>
                        {
                            reportee ?
                                <Button
                                    styles={{ marginRight: '4px' }}
                                    icon={<i class="bi bi-diagram-2" style={{ fontSize: '21px' }}></i>}
                                    iconOnly
                                    title="Manager Insight"
                                    onClick={() => fetchData(reporteeGUID)}
                                /> : <></>
                        }
                        {!isNotManager ? <Button
                            icon={<i class="bi bi-person-bounding-box" style={{ fontSize: '18px' }}></i>}
                            iconOnly
                            title="User Insight"
                            onClick={() => fetchData(null, reporteeGUID)}
                        /> : <></>
                        }

                    </>
                }
            </div>
        )
    }

    return (

        <>
            <div className={isNotManager ? "non-managercard" : "insights-card-container"}>
                <div className={isNotManager ? "non-managercardcursor" : (`${(forDialog) ? 'm-3' : 'insights-card'} cursor-default ${(teamView) ? forDialog ? '' : 'detail-view-width' : 'list-view-width'}`)} key={key}>
                    <div className="container">
                        <InsightCardHeader
                            teamView
                            userName={data.userName}
                            userDesignation={data.userDesignation}
                            userEmail={data.userEmail}
                            userStatus={data.userStatus}
                            userLabel={data.userLabel}
                            vibe={data.vibe}
                            reportee={data.reporteeCount}
                            reporteeGUID={data.userGUID}
                            userImage={data.userImage}
                            fetchData={fetchData}
                            managerData={forDialog}
                        /><div className="insights-card-body">
                            {
                                (teamView) ? <>
                                    <div className='row border-b-uinsights pb-3'>
                                        <div className="col px-2">
                                            <HSCProgress
                                                label={'Meetings'}
                                                progress={data.meetingsPercentage}
                                                zones={data.meetingsZone}
                                            />

                                            <HSCProgress
                                                label={'Messages'}
                                                progress={data.callsPercentage}
                                                zones={data.callsZone}
                                            />

                                            <HSCProgress
                                                label={'Focused'}
                                                progress={data.focusedPercentage}
                                                zones={data.focusedZone}
                                            />
                                            {/* <MultiSegmentRangeSlider
                                                fieldName="percentageTimeInMeetings"
                                                headerText="Meetings"
                                                displayValue={data.meetingsPercentage}
                                                compact={true}
                                                setValue={data.meetingsPercentage}
                                                setZoneBars={data.meetingsZone}
                                                draggable={false}
                                                readonly={true}
                                                min={0}
                                                max={100}
                                            />
                                            <MultiSegmentRangeSlider
                                                fieldName="percentageTimeInCallsAndMessages"
                                                headerText="Messages"
                                                displayValue={data.callsPercentage}
                                                compact={true}
                                                setValue={data.callsPercentage}
                                                setZoneBars={data.callsZone}
                                                draggable={false}
                                                readonly={true}
                                                min={0}
                                                max={100}
                                            />
                                            <MultiSegmentRangeSlider
                                                fieldName="percentageTimeInFocus"
                                                headerText="Focused"
                                                displayValue={data.focusedPercentage}
                                                compact={true}
                                                setValue={data.focusedPercentage}
                                                setZoneBars={data.focusedZone}
                                                draggable={false}
                                                readonly={true}
                                                min={0}
                                                max={100}
                                            /> */}
                                        </div>
                                    </div>
                                    <div className='row align-items-center my-2 pb-2 border-b-uinsights'>
                                        <div className='col-auto pr-2 p-0 me-auto'>
                                            <div className='name-label'>Sentiments</div>
                                        </div>
                                        <TeamViewIndicator type='Neutral' value={data.dviNeutralVal} />
                                        <TeamViewIndicator type='Positive' value={data.dviPositiveVal} />
                                        <TeamViewIndicator type='Negative' value={data.dviNegativeVal} />
                                    </div>
                                    <div className='row align-items-center my-2'>
                                        <div className='col-auto pr-2 p-0 me-auto'>
                                            <div className='name-label'>Wellbeing</div>
                                        </div>
                                        <div className="col pl-2 p-0 ml-3">
                                            <div className="row align-items-center">
                                                {data.questionTag.map((items) => <WellbeingPill label={items.label} val={items.val} />)}
                                            </div>
                                        </div>
                                    </div>
                                </>
                                    :
                                    <div className='row justify-content-center list-view-indicator-margin'>
                                        <ListViewIndicator type={data.lvTypeMeeting} label='Meetings' />
                                        <ListViewIndicator type={data.lvTypeMessages} label='Messages' />
                                        <ListViewIndicator type={data.lvTypeFocused} label='Focused' />
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default InsightsCard