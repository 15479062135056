import {useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Flex,
    Dialog as FluentDialog,
    Text,
    Box,
    Grid,
    Button,
    Avatar,
    Loader,
    Segment
  } from '@fluentui/react-northstar'

  import {
    ContactGroupIcon,
    ChevronEndIcon
} from '@fluentui/react-icons-northstar'
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import * as Hammer from 'hammerjs';
import './appoverview.scss';
import DashboardApiService from '../../../../services/DashboardApiService';
import FiltersApiService from '../../../../services/FiltersApiService';
import AuthHelper from '../../../../services/auth-helper';
import WindowContext from '../../Context/Context';
import ReactPlayer from "react-player"

const AppOverview = (props) => {
    const [loader, setLoader] = useState(false);
    let _history = useHistory();
    const { state } = useLocation();
    const { from } = state || { from: { pathname: "/" } };
    const [overviewData, setOverviewData] = useState(null);

    // const assignEventHandler = () =>{
    //     var myElement = document.getElementById('overview-content-holder');
    //     var mc = new Hammer(myElement);
    //     // listen to events...
    //     mc.on("swiperight", function(e) {
    //         setActiveIndex(activeIndex > 0 ? activeIndex - 1 : 0);
    //     });
    //     mc.on("swipeleft", function(e) {
    //         setActiveIndex(activeIndex < 2 ? activeIndex + 1 : 2);
    //     });
    // }
    useEffect(()=> {
        AuthHelper.getAccessToken(function (token) {
            FiltersApiService.GetOverviewData(token).then((response) => {
                //let data = response;
                // data.teamFilter = data.teamFilter.items.map((obj) => ({
                // ...obj,
                // key: obj.value,
                // }));
                // data.groupFilter = data.groupFilter.items.map((obj) => ({
                // ...obj,
                // key: obj.value,
                // }));
                // data.userFilter = data.userFilter.items.map((obj) => ({
                // ...obj,
                // key: obj.value,
                // }));
                setOverviewData(response);
            });
        });
        
    }, []);
    const onFinish = () => {
        setLoader(true);
        AuthHelper.getAccessToken(function(token){
            DashboardApiService.AddDefaultDashboards(false, token).then((response) => {
              if(response){                  
                    //setOpen(false);
                  if (global.localStorage) {
                      global.localStorage.setItem(
                          "contentUrl_dashboards",
                          "/dashboards/"
                      );
                  }
                  setLoader(false);
                  if (from.pathname.includes('dashboards')) {
                      _history.replace('/dashboards');
                  }
                  else {
                      _history.replace(from.pathname);
                  }
                    
              }
            }); 
          });        
    }
    return (
        <>
        <WindowContext.Consumer>
        {(context) =>
        overviewData == null ? (
          <Loader label="Loading..." />
        ) : (
            <Dialog
            autoFocus={true}
            >
            <Flex column fill>
               <div style={{overflow:'hidden', minHeight:'60vh'}}>                
                   { loader && <Loader className="overview-loader" label="Getting things ready..." /> }
                   <div id="overview-content-holder" className="overview-content-holder">
                        <div className="commentsContainer">
                            <Text size="larger" weight="bold" content={`Hi, ${overviewData.user.header.split(' ')[0]}!`}/>
                            <Text size="large" weight="semibold" content={"Let's get you started..."}/>
                        </div>
                        <div className="videoContainer">
                        {/* <Video
                            src="https://raw.githubusercontent.com/bower-media-samples/big-buck-bunny-480p-30s/master/video.mp4"
                            variables={{
                                width: '100%',
                                height: '100%'
                            }}
                            /> */}
                            <ReactPlayer width="100%" url="https://vimeo.com/518145817/bc54d94ec9" controls={true} />
                        </div>
                       <Grid
                       className="listContainer"
                        columns={1}
                        styles={{
                            width: "100%",
                            marginTop: "0",
                            marginBottom: "0",
                            alignItems: "top",
                        }}
                        >
                        <Text styles={{ color: "#666", lineHeight: 1, marginBottom: "8px" }} size="small" content="Currently you have permission to report on the following:" />
                        {
                            overviewData.users && overviewData.users.length > 0 ? (
                            <div>
                            <Box style={{ marginBottom: "0.5rem" }}>
                                <Text
                                    styles={{
                                        color: "#666",
                                        lineHeight: 1,
                                        marginLeft: "5px",
                                    }}
                                    size="medium"
                                    content="People"
                                />
                            </Box>           
                                <Flex>
                                {
                                    overviewData.users.map((people) => {
                                        return (
                                            <Avatar
                                                image={people.additionalcontent}
                                                label={people.value === -1 ? people.header : ""}
                                                name={people.value !== -1 ? people.header : ""}
                                                size={
                                                    context.mediaCategory.sm
                                                        ? "medium"
                                                        : "large"
                                                }
                                            />
                                        );
                                    })
                                }
                                </Flex>
                            </div>
                                ):(
                                    <></>
                                )
                        }
                        {
                            overviewData.teams && overviewData.teams.length > 0 ? (
                            <div>
                            <Box style={{ marginBottom: "0.5rem" }}>
                            <Text
                                styles={{ color: "#666", lineHeight: 1, marginLeft: "5px" }}
                                content="Teams"
                                size="medium"
                            />
                            </Box>
                            <Flex>
                                {
                                    overviewData.teams.map((team) => {
                                        return (
                                            <Avatar
                                                label={team.value === -1 ? team.header : ""}
                                                name={team.value !== -1 ? team.header : ""}
                                                size={context.mediaCategory.sm ? "medium" : "large"}
                                            />
                                        );
                                    })
                                }
                                </Flex>
                        </div>
                        ):(<></>)}
                        {overviewData.groups && overviewData.groups.length > 0 ? (
                            <div>
                            <Box style={{ marginBottom: "0.5rem" }}>
                            <Text
                                styles={{ color: "#666", lineHeight: 1, marginLeft: "5px" }}
                                content="Groups"
                                size="medium"
                            />
                            </Box>
                            <Flex>
                                {
                                    overviewData.groups.map((group) => {
                                        return (
                                            <Avatar
                                                label={group.value === -1 ? group.header : ""}
                                                name={group.value !== -1 ? group.header : ""}
                                                size={context.mediaCategory.sm ? "medium" : "large"}
                                            />
                                        );
                                    })
                                }
                            </Flex>
                        </div>
                        ):(<></>)}
                          <Text styles={{ color: "#666", lineHeight: 1, marginTop: "12px" }} size="small" content="*App owners can go on to Settings section to configure additional permissions." />                      
                        </Grid>
                   </div>
               </div>
               <Flex styles={{marginTop:"15px"}}>
                   <Flex.Item push>
                   <Button disabled={loader} primary content="Finish" onClick={() => onFinish()}/>
                   </Flex.Item>
               </Flex>
            </Flex>
            </Dialog>
        )
      }
        </WindowContext.Consumer>
        
        </>
    )
} 

export default AppOverview  