import React from 'react';
import './insights.scss';
import { Skeleton } from '@fluentui/react-northstar';
function InsightsCardSkeleton({ key, teamView, numOfCards, forDialog = false, }) {

    // const ListViewIndicator = ({ type, label = 'Activity' }) => {
    //     return (
    //         <div className='col-auto px-2 d-flex align-items-center'>
    //             {type && <div className={`list-view-indicator ${type}`} title={`${type} zone`}></div>}
    //             <div className='list-view-label px-2' title={label === 'Messages' ? 'Chats & Messages' : label}>{label}</div>
    //         </div>
    //     )
    // }

    // const teamViewIndicator = ({ type, value = '!' }) => {
    //     return (
    //         <div className='col-auto pl-2 pr-1 d-flex align-items-center'>
    //             {type && <div className={`detail-view-indicator ${type}`} title={`${type} zone`}></div>}
    //             <div className='detail-view-label px-2' title={type + ' People'}>{type} - {value}</div>
    //         </div>
    //     )
    // }

    const InsightCardHeader = (
        // { userName = 'User name', userEmail = 'user.name@company.com', userImage, userDesignation = 'no data', vibe = 'Neutral', reportee, loading = false, reporteeGUID, fetchData }
    ) => {
        return (

            <Skeleton animation='pulse'>
                <div className='row insights-card-header align-items-center'>
                    {/* {loading ? <>
                        <div className=''>
                            <Avatar
                                name={userName}
                                size="large"
                                image={userImage}
                            />
                        </div>
                        <div className='col px-2 labels'>
                            <div className='name-label'>{userName}</div>
                            <div className='designation-label'>{userDesignation}</div>
                            {(teamView) && <div className='org-email'>{userEmail}</div>}
                        </div>
                    </> : <> */}
                    <div className=''>
                        <Skeleton.Avatar size='large' className='shadow rounded-circle' />
                    </div>
                    <div className='col px-2 labels'>
                        <Skeleton.Line width="40%" />
                        <Skeleton.Line width="20%" />
                        <Skeleton.Line width="60%" />
                    </div>
                    {/* </>} */}
                    {/* {(!teamView) ?
                        (<div className={`vibe-indicator align-self-end ${(!teamView) ? '' : 'mb-2'}`}>
                            <div className='vibe-indicator-emoji' >
                                {vibe === 'positive' ? <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Smiling%20Face%20with%20Halo.png" alt="Neutral Face" width="38" height="38" />
                                    : (vibe === 'negative' ? <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Worried%20Face.png" alt="Worried Face" width="38" height="38" />
                                        : <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Neutral%20Face.png" alt="Neutral Face" width="38" height="38" />
                                    )}
                            </div>
                            <div className='vibe-indicator-text bg-primary text-right'>
                                {vibe === 'positive' ? 'Positive' : (vibe === 'negative' ? 'Negative' : 'Neutral')}
                            </div>
                        </div>) : <>
                            {
                                reportee &&
                                <Button
                                    styles={{ marginRight: '4px' }}
                                    icon={<i class="bi bi-diagram-2" style={{ fontSize: '21px' }}></i>}
                                    iconOnly
                                    title="Manager Insight"
                                    onClick={() => fetchData(reporteeGUID)}
                                />
                            }
                            {loading ? <Button
                                icon={<i class="bi bi-person-bounding-box" style={{ fontSize: '18px' }}></i>}
                                iconOnly
                                title="User Insight"
                                onClick={() => fetchData(null, reporteeGUID)}
                            /> :  */}
                    <Skeleton.Shape width="30px" height='30px' />
                    {/* }
                        </>
                    } */}
                </div>
            </Skeleton>
        )
    }

    return (

        <>
            <div className="insights-card-container">
                <div className={`cursor-default ${forDialog ? 'm-3' : 'detail-view-width insights-card'}`} key={key}>
                    <div className="container">
                        <InsightCardHeader
                        // loading={loading}
                        // teamView
                        // userName={data.userName}
                        // userDesignation={data.userDesignation}
                        // userEmail={data.userEmail}
                        // userStatus={data.userStatus}
                        // userLabel={data.userLabel}
                        // vibe={data.vibe}
                        // reportee={data.reporteeCount}
                        // reporteeGUID={data.userGUID}
                        // userImage={data.userImage}
                        // fetchData={fetchData}
                        />
                        {/* {loading ? 
                        <div className="insights-card-body">
                            {
                                (teamView) ? <>
                                    <div className='row border-bottom pb-3'>
                                        <div className="col px-2">
                                            <HSCProgress
                                                label={'Meetings'}
                                                progress={data.meetingsPercentage}
                                                zones={data.meetingsZone}
                                            />

                                            <HSCProgress
                                                label={'Messages'}
                                                progress={data.callsPercentage}
                                                zones={data.callsZone}
                                            />

                                            <HSCProgress
                                                label={'Focused'}
                                                progress={data.focusedPercentage}
                                                zones={data.focusedZone}
                                            />
                                        </div>
                                    </div>
                                    <div className='row align-items-center my-2 pb-2 border-bottom'>
                                        <div className='col-auto pr-2 p-0 me-auto'>
                                            <div className='name-label'>Sentiments</div>
                                        </div>
                                        <TeamViewIndicator type='Neutral' value={data.dviNeutralVal} />
                                        <TeamViewIndicator type='Positive' value={data.dviPositiveVal} />
                                        <TeamViewIndicator type='Negative' value={data.dviNegativeVal} />
                                    </div>
                                    <div className='row align-items-center my-2'>
                                        <div className='col-auto pr-2 p-0 me-auto'>
                                            <div className='name-label'>Wellbeing</div>
                                        </div>
                                        <div className="col pl-2 p-0">
                                            <div className="row align-items-center">
                                                {data.questionTag.map((items) => <ListViewIndicator label={`${items.label} - ${items.val}`} />)}
                                            </div>
                                        </div>
                                    </div>
                                </>
                                    :
                                    <div className='row justify-content-center list-view-indicator-margin'>
                                        <ListViewIndicator type={data.lvTypeMeeting} label='Meetings' />
                                        <ListViewIndicator type={data.lvTypeMessages} label='Messages' />
                                        <ListViewIndicator type={data.lvTypeFocused} label='Focused' />
                                    </div>
                            }
                        </div> :  */}
                        <div className="insights-card-body">
                            <div className='row border-b-uinsights pb-3'>
                                <div className="col justify-content-center px-2">
                                    <Skeleton.Line width="80%" />
                                    <Skeleton.Line width="80%" />
                                    <Skeleton.Line width="80%" />
                                </div>
                            </div>
                            <div className='row align-items-center justify-content-center my-2 pb-2 border-b-uinsights'>
                                <Skeleton.Line width="90%" />
                            </div>
                            <div className='row align-items-center justify-content-center my-2'>
                                <Skeleton.Line width="90%" />
                            </div>

                        </div>
                        {/* } */}
                    </div>
                </div>
            </div>
        </>

    )
}

export default InsightsCardSkeleton