import React, { useState, useEffect, useMemo } from 'react';
import { Dialog as FluentDialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { Dialog } from "@progress/kendo-react-dialogs";
import { TextField } from '@fluentui/react/lib/TextField';
import { Stack } from '@fluentui/react/lib/Stack';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { useId } from '@fluentui/react-hooks';
import { Flex, Box, Input, Avatar, Loader, Text, Dropdown, Label, Button, FlexItem } from "@fluentui/react-northstar";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { SearchIcon, CloseIcon, AddIcon, TrashCanIcon, EditIcon } from "@fluentui/react-icons-northstar";
import ConfigurationApiService from "../../../services/ConfigurationApiService";
import AuthHelper from "../../../services/auth-helper";
import HSCProgress from '../insights/hscProgress';
import '../insights/insights.scss';
import WindowContext from '../../Shared/Context/Context';
import MultiSegmentRangeSlider from '../../Shared/uicomponents/MultiSegmentRangeSlider';
import { OPERATIONSTATUS, OperationStatusIndicator } from "../../Shared/uicomponents/OperationStatus/index";
import { Error403 } from "../../Shared/Error/403";
import { Error500 } from "../../Shared/Error/500";

const CommunicationProfile = (props) => {
    const [loading, setLoading] = useState(true);
    const [profileData, setProfileData] = useState(null);
    const [openProfileDialog, setOpenProfileDialog] = useState(false);
    const [isEditMode, setEditMode] = useState(true);
    const [selectedUserProfile, setSelectedUserprofile] = useState(null);
    const [dataRefresh, setDataRefresh] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [AssignedUserDialogOpen, setAssignedUserDialogOpen] = useState(false);
    const styles = {
        profileContainer: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        profileCard: {
            flex: '1 1 calc(50% - 32px)',
            boxSizing: 'border-box',
            margin: '16px',
        },
    };
    useEffect(() => {
        setLoading(true);
        props.viewStatus.setPageStatus(null);
        props.viewStatus.InformChildPageStatus(null);
        AuthHelper.getAccessToken((token) => {
            ConfigurationApiService.GetCustomerCommunicationProfiles(token).then((response) => {
                if (response && response.status == 200) {
                    const sortedProfiles = response.data.sort((a, b) => {
                        const dateValueA = a.dateUpdated ? new Date(a.dateUpdated) : new Date(a.dateCreated);
                        const dateValueB = b.dateUpdated ? new Date(b.dateUpdated) : new Date(b.dateCreated);
                        return dateValueB - dateValueA;
                    });
                    setProfileData(sortedProfiles);
                }
                //else {
                //    alert('Error in fetching data');
                //}

                props.viewStatus.setPageStatus(response.status);
                setLoading(false);
            });
        });
    }, [dataRefresh, props.tenantDetails.id]);

    const handleDelete = (alternateId) => {

        AuthHelper.getAccessToken((token) => {
            ConfigurationApiService.DeleteCustomerCommunicationProfiles(token, selectedUserProfile.id, alternateId).then((response) => {
                if (response && response.status == 200) {
                    setDataRefresh(!dataRefresh);
                    props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
                }
                else if (response.status == 401)
                    props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
                else {
                    props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
                   // alert('Error in deletion');
                }
                
            });
        });
    };

    const filteredProfileData = profileData && profileData.filter(profile => profile.profileName.toLowerCase().includes(searchQuery.toLowerCase()));
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const ProfileSettingHeader = (props) => {
        const { profile, assignedTo, setOpenProfileDialog, setEditMode, setSelectedUserprofile, profileData, setDataRefresh, dataRefresh } = props;

        const handleEdit = (id) => {
            const selectedProfile = profileData.find(profile => profile.id === id);
            setSelectedUserprofile(selectedProfile);
            setEditMode(true);
            setOpenProfileDialog(true);
        };

        const handleUsers = (id) => {
            setAssignedUserDialogOpen(true);
            const selectedProfile = profileData.find(profile => profile.id === id);
            setSelectedUserprofile(selectedProfile);
        };

        const handleDeleteConfirmation = (id) => {
            const profileToDelete = profileData.find(profile => profile.id === id);
            setSelectedUserprofile(profileToDelete);
            setShowDeleteConfirmation(true);
        };

        return (
            <div className='row insights-card-header align-items-center'>
                <div className='col px-2 labels'>
                    <div className='name-label'>{profile.profileName}</div>
                    {profile.createdBy === "System" ? (
                        <div className='org-email'>System defined</div>
                    ) : null}
                    <div onClick={() => handleUsers(profile.id)} className='assigned-users-label'>
                        Assigned to {assignedTo} users
                    </div>
                </div>
                <div className='col d-flex px-2 labels justify-content-end align-items-center'>
                    <div className="mx-1">
                        <Button
                            primary
                            icon={<EditIcon />}
                            text
                            iconOnly
                            title="Edit"
                            onClick={() => handleEdit(profile.id)}
                        />

                        {/* <button onClick={() => handleEdit(profile.id)} className='bg-custome-light border px-3 py-1 rounded-pill' title='delete'>
                            Edit
                        </button> */}
                    </div>
                    {profile.createdBy !== "System" ? (
                        <div className="ml-1">
                            <Button
                                className='text-danger'
                                icon={<TrashCanIcon />}
                                text
                                iconOnly
                                title="Delete"
                                onClick={() => handleDeleteConfirmation(profile.id)}
                            />
                            {/* <button onClick={() => handleDeleteConfirmation(profile.id)} className='bg-danger-del border-0' title='Delete'>
                                <i class="bi bi-trash3"></i>
                            </button> */}
                        </div>) : null}
                </div>
            </div>

        )
    };

    const InsightsCard = (props) => {
        const { profile, setOpenProfileDialog, setEditMode, setSelectedUserprofile, profileData, setDataRefresh, dataRefresh } = props;
        return (
            <div className="insights-card cursor-default">
                <div className="container h-100">
                    <ProfileSettingHeader
                        profile={profile}
                        assignedTo={profile.assignedTo}
                        setOpenProfileDialog={setOpenProfileDialog}
                        setEditMode={setEditMode}
                        setSelectedUserprofile={setSelectedUserprofile}
                        profileData={profileData}
                        setDataRefresh={setDataRefresh}
                        dataRefresh={dataRefresh}
                    />
                    <div className="insights-card-body">
                        <div className='row border-top pt-2'>
                            <div className="col px-2">
                                <MultiSegmentRangeSlider
                                    viewOnly={true}
                                    fieldName="percentageTimeInMeetings"
                                    headerText="Percentage time in meetings"
                                    setValue={profile.percentageTimeInMeetings}
                                    defaultGreenZonePercentage={20}
                                    defaultYellowZonePercentage={40}
                                    setZoneBars={profile.meetingsZone}
                                    draggable={false}
                                    readonly={true}
                                    min={0}
                                    max={100}
                                />
                                <MultiSegmentRangeSlider
                                    viewOnly={true}
                                    fieldName="percentageTimeInCallsAndMessages"
                                    headerText="Percentage time in calls & messages"
                                    setValue={profile.percentageTimeInCallsAndMessages}
                                    defaultGreenZonePercentage={20}
                                    defaultYellowZonePercentage={40}
                                    setZoneBars={profile.callsZone}
                                    draggable={false}
                                    readonly={true}
                                    min={0}
                                    max={100}
                                />
                                <MultiSegmentRangeSlider
                                    viewOnly={true}
                                    fieldName="percentageTimeInFocus"
                                    headerText="Percentage time in focus"
                                    setValue={profile.percentageTimeInFocus}
                                    defaultGreenZonePercentage={20}
                                    defaultYellowZonePercentage={40}
                                    setZoneBars={profile.focusedZone}
                                    draggable={false}
                                    readonly={true}
                                    min={0}
                                    max={100}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const handleProfileUpdate = (data) => {
        debugger
        const Bodydata = {
            CustomerProfileId: data.id ? data.id : 0,
            CustomerProfileName: data.profileName,
            PercentageTimeInMeetings: parseFloat(data.percentageTimeInMeetings),
            PercentageTimeInCallsAndMessages: parseFloat(data.percentageTimeInCallsAndMessages),
            PercentageTimeInFocus: parseFloat(data.percentageTimeInFocus),
            MeetingsZone: data.meetingsZone,
            CallsZone: data.callsZone,
            FocusedZone: data.focusedZone
        };
        AuthHelper.getAccessToken((token) => {
            ConfigurationApiService.AddorUpdateCustomerCommunicationProfiles(token, Bodydata).then((response) => {
                if (response && response.status == 200) {
                    setOpenProfileDialog(false);
                    setDataRefresh(!dataRefresh);
                    props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
                } else if (response.status == 401)
                    props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
                else {
                    props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
                    // alert('Error');
                }
            });
        });
    };

    const handleCloseEditProfileAction = () => {
        setOpenProfileDialog(false);
        setSelectedUserprofile(null);
    };
    const handleAddProfile = () => {
        setEditMode(false);

        setSelectedUserprofile({
            id: -1,
            percentageTimeInCallsAndMessages: 30,
            percentageTimeInMeetings: 30,
            percentageTimeInFocus: 40,
            profileName: "",

        });

        setOpenProfileDialog(true);
    };

    return (
        <WindowContext.Consumer>
            {
                context => (
                    (loading) ? <Loader /> :
                        props.viewStatus.pageStatus == 401 ? <Error403 /> :
                            props.viewStatus.pageStatus == 500 ? <Error500 /> :
                    <div>
                        <div className="row align-items-center justify-content-center m-2">
                            <div className="col px-0 me-auto">
                                <h5>Communication Profile</h5>
                                <h>Lorem ipsum dolor sit amet. Et internos quae ad quibusdam voluptatem ut esse dignissimos sed dolor harum et Quis veritatis qui nobis blanditiis ea error deserunt. Et velit soluta est corrupti animi sed corrupti vitae aut minus error sit rerum maiores.</h>
                            </div>
                        </div>
                        <Flex gap="gap.small" padding="padding.medium" fill>
                            <Input
                                    fluid
                                    icon={<SearchIcon />}
                                    clearable
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    placeholder="Search..."

                                />
                            <FlexItem push>
                                <Button
                                    onClick={handleAddProfile}
                                    icon={<AddIcon />}
                                    content="Add New"
                                    // size="small"
                                    primary
                                />
                            </FlexItem>
                        </Flex>
                        {loading ? (
                            <Loader size="small" />
                        ) : (
                            <Flex>
                                <div style={styles.profileContainer}>
                                    {(searchQuery ? filteredProfileData : profileData) && (searchQuery ? filteredProfileData : profileData).map((profile, index) => (
                                        <div key={index} style={styles.profileCard}>
                                            <InsightsCard
                                                profile={profile}
                                                setOpenProfileDialog={setOpenProfileDialog}
                                                setEditMode={setEditMode}
                                                setSelectedUserprofile={setSelectedUserprofile}
                                                profileData={profileData}
                                                setDataRefresh={setDataRefresh}
                                                dataRefresh={dataRefresh}
                                            />
                                        </div>
                                    ))}
                                </div>
                                {(openProfileDialog && selectedUserProfile != null) && (
                                    <Dialog
                                        className="settingsDialog"
                                        title={isEditMode  ? "Edit" : "Add New"}
                                        onClose={() => handleCloseEditProfileAction()}
                                    >
                                        <EditForm showDialog={openProfileDialog} isEditMode={isEditMode} profileData={selectedUserProfile} handleSaveProfile={handleProfileUpdate} onClose={() => handleCloseEditProfileAction()} />
                                    </Dialog>
                                )}
                                {showDeleteConfirmation && (
                                    <DeleteConfirmationDialog
                                        showDialog={showDeleteConfirmation}
                                        profile={selectedUserProfile}
                                        handleDeleteProfile={handleDelete}
                                        onClose={setShowDeleteConfirmation}
                                        allProfile={profileData}
                                    />
                                )}
                                {AssignedUserDialogOpen && (
                                    <AssignedUsers
                                                open={AssignedUserDialogOpen}
                                                onClose={() => {
                                                    setAssignedUserDialogOpen(false);
                                                    setDataRefresh(!dataRefresh);
                                                }}
                                                profileId={selectedUserProfile.id}
                                                profileData={profileData}
                                    />
                                )}
                            </Flex>
                        )}
                    </div>
                )
            }
        </WindowContext.Consumer>

    );
};

const AssignedUsers = ({ open, onClose, profileData, profileId}) => {
    const initialDataState = { skip: 0, take: 10 };
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [page, setPage] = React.useState(initialDataState);
    const filteredProfileData = users && users.filter(profile => profile.displayName.toLowerCase().includes(searchQuery.toLowerCase()));
    const dialogStyles = {
        width: '100%',
        height: '90%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
    };
    useEffect(() => {
        AuthHelper.getAccessToken((token) => {
            setLoading(true);
            ConfigurationApiService.GetAssignedUsersProfile(token, profileId).then((response) => {
                if (response) {
                    setUsers(response);
                }
                else {
                    alert('Error in fetching data');
                }
                setLoading(false);
            });
        });
    }, [profileId]);

    const pageChange = (event) => {
        setPage(event.page);
    };

    const handleSelectAllToggle = () => {
        if (selectedUsers.length === users.length) {
            setSelectedUsers([]);
        } else {
            setSelectedUsers(users.map((item) => item.userId));
        }
    };
    const handleToggle = (userId) => {
        setSelectedUsers((prevSelected) =>
            prevSelected.includes(userId)
                ? prevSelected.filter((id) => id !== userId)
                : [...prevSelected, userId]
        );
    };

    const CellWithCommunicationUserColumn = (props) => {
        const user = props.dataItem;
        return (
            <td colSpan={1}>
                <Flex gap="gap.medium">
                    <Avatar
                        name={user.displayName}
                        image={user.userImage}
                        size="large"
                    />
                    <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Text content={user.displayName} size="medium" />
                        <Text content={user.jobTitle} size="small" />
                        <Text content={user.mail} size="small" />
                    </div>
                </Flex>
            </td>
        );
    };

    const ProfileNameCell = (props) => {
        const { dataItem } = props;
        const [loaderGrid, setLoader] = useState(false);
        const profileOptions = profileData.map(item => ({
            id: item.id,
            key: item.id,
            header: item.profileName
        }));

        return (
            loaderGrid ? (
                <Loader size="small" />
            ) : (
                <td>
                    <Dropdown
                        inline
                        items={profileOptions}
                        value={profileOptions.find(option => option.key === dataItem.customerProfileId)?.header}
                        onChange={(e, { value }) => {

                            const assignedList = [dataItem.userId];
                            const data = {
                                Ids: assignedList,
                                UpdatedCustomerId: value.key
                            };
                            AuthHelper.getAccessToken((token) => {
                                setLoader(true);
                                ConfigurationApiService.UpdateAssignedProfiles(token, data).then((response) => {
                                    if (response) {
                                        setUsers(users.map(user =>
                                            user.userId === dataItem.userId
                                                ? { ...user, customerProfileId: value.key }
                                                : user
                                        ));
                                    } else {
                                        alert('Error in fetching data');
                                    }
                                    setLoader(false);
                                });
                            });
                        }}
                        itemToValue={(obj) => obj.key}
                    />
                </td>
            )
        );
    };

    const sortedData = filteredProfileData.slice().sort((a, b) => a.displayName.localeCompare(b.displayName));
    const removeFromSelectedUsers = (userIdToRemove) => {
        const updatedUsers = selectedUsers.filter(userId => userId !== userIdToRemove);
        setSelectedUsers(updatedUsers);
    };

    const ProfileDropdown = ({ profileData, profileId }) => {
        const [loader, setLoader] = useState(false);
        return (
            <Flex className='pl-1 pr-1 mt-2' vAlign='center' gap="gap.small" fluid>
                <Text
                    content="Please select the communication profile to update for selected users: "
                    style={{
                        fontSize: '14px'
                    }}
                />
                {loader ? (
                    <Loader size="small" />
                ) : (
                    <Dropdown
                        styles={{ minWidth: '180px' }}
                        label="Profile Name"
                        header='Profile Name'
                        title='Profile Name'
                        items={profileData.map(item => ({
                            id: item.id,
                            key: item.id,
                            header: item.profileName
                        }))}
                        value={profileData.find(profile => profile.id === profileId)?.profileName || ''}
                        onChange={(e, { value }) => {
                            const data = {
                                Ids: selectedUsers,
                                UpdatedCustomerId: value.key
                            };

                            AuthHelper.getAccessToken((token) => {
                                setLoader(true);
                                ConfigurationApiService.UpdateAssignedProfiles(token, data).then((response) => {
                                    if (response) {
                                        const updatedUsers = users.map(user =>
                                            selectedUsers.includes(user.userId)
                                                ? { ...user, customerProfileId: value.key }
                                                : user
                                        );
                                        setUsers(updatedUsers);
                                        setSelectedUsers([]);
                                    } else {
                                        alert('Error in fetching data');
                                    }
                                    setLoader(false);
                                });
                            });
                        }}
                        itemToValue={(obj) => {
                            return obj.key;
                        }}
                    />)}
            </Flex>
        );
    };

    return (
        <Dialog
            title="Assigned Users"
            onClose={onClose}
            open={open}
            style={dialogStyles}
        >
            {selectedUsers != null && selectedUsers.length > 0 ? (
                <Box style={{ border: '1px solid #CCC', padding: '8px', margin: '0.625rem' }}>
                    <Box style={{ maxHeight: "100px", overflow: "auto" }}>
                        {selectedUsers != null &&
                            users.filter(user => selectedUsers.includes(user.userId))
                                .map((user) => {
                                    return (
                                        <Label
                                            color="brand"
                                            key={user.userId}
                                            content={user.displayName}
                                            icon={
                                                <CloseIcon
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => removeFromSelectedUsers(user.userId)}
                                                />
                                            }
                                            style={{ margin: "3px" }}
                                        />
                                    );
                                })
                        }
                    </Box>
                    <Box>
                        <ProfileDropdown
                            profileData={profileData}
                            profileId={profileId}
                        />
                    </Box>
                </Box>
            ) : <></>
            }
            <Flex gap="gap.small" padding="padding.medium" column fill>
                <Flex.Item>
                    <Input
                        fluid
                        icon={<SearchIcon />}
                        clearable
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search..."
                    />
                </Flex.Item>
                {loading ? (
                    <Loader size="small" />
                ) : (
                    <Grid
                        data={sortedData.slice(page.skip, page.take + page.skip)}
                        style={{ height: 'calc(85% - 10px)' }}
                        skip={page.skip}
                        take={page.take}
                        total={users != null ? users.filter((user) => user.displayName.toLowerCase().includes(searchQuery.toLowerCase())).length : 0}
                        pageable={{
                            buttonCount: 2,
                            info: true,
                        }}
                        onPageChange={pageChange}
                    >
                        <Column
                            width="60px"
                            headerCell={() => (
                                <Flex fluid vAlign='center' hAlign='center'>
                                    <input
                                        type="checkbox"
                                        checked={selectedUsers.length === users.length}
                                        onChange={handleSelectAllToggle}
                                    />
                                </Flex>
                            )}
                            cell={(props) => (<td>
                                <Flex fluid vAlign='center' hAlign='center'>
                                    <input
                                        type="checkbox"
                                        checked={selectedUsers.includes(props.dataItem.userId)}
                                        onChange={() => handleToggle(props.dataItem.userId)}
                                    />
                                </Flex>
                            </td>
                            )}
                        />
                        <Column
                            title="User"
                            width="250px"
                            cell={CellWithCommunicationUserColumn}
                        />
                        <Column
                            title="Profile Name"
                            field="profileName"
                            width="200px"
                            cell={ProfileNameCell}
                        />
                    </Grid>
                )}
            </Flex>
        </Dialog>
    );
};

const EditForm = ({ showDialog, onClose, isEditMode, profileData, handleSaveProfile }) => {
    const [profile, setProfile] = useState({
        profileName: profileData?.profileName || '',
        meetingsTime: profileData?.percentageTimeInMeetings || 30,
        callsMessagesTime: profileData?.percentageTimeInCallsAndMessages || 30,
        focusedTime: profileData?.percentageTimeInFocus || 40,
        meetingsZone: profileData?.meetingsZone,
        callsZone: profileData?.callsZone,
        focusedZone: profileData?.focusedZone
    });

    const [saveDisabled, setSaveDisabled] = useState(isEditMode ? false : true);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const onSave = () => {
        debugger
        const totalPercentage = parseFloat(profile.meetingsTime) + parseFloat(profile.callsMessagesTime);

        if (totalPercentage > 100 || totalPercentage < 0) {
            setError("Total percentage should be exactly 100.");
            return;
        }

        if (profile.profileName.trim() === '' || profile.meetingsTime === '' || profile.callsMessagesTime === '') {
            setError("Please fill in all fields.");
            return;
        }

        setError('');
        setSaveDisabled(true);

        if (onClose) {
            onClose(false);
        }
        const updatedProfileData = {
            ...profileData,
            profileName: profile.profileName,
            percentageTimeInMeetings: Number(profile.meetingsTime),
            percentageTimeInCallsAndMessages: Number(profile.callsMessagesTime),
            percentageTimeInFocus: 100 - (Number(profile.meetingsTime) + Number(profile.callsMessagesTime)),
            meetingsZone: profile?.meetingsZone,
            callsZone: profile?.callsZone,
            focusedZone: profile?.focusedZone
        };
        handleSaveProfile(updatedProfileData);
    };

    const handleSliderInputChange = (field, newValue) => {
        const totalPercentage =
            parseInt(field === 'meetingsTime' ? newValue : profile.meetingsTime) +
            parseInt(field === 'callsMessagesTime' ? newValue : profile.callsMessagesTime);

        debugger
        if (totalPercentage <= 100) {
            debugger
            setProfile(prevState => ({
                ...prevState,
                [field]: newValue,
                focusedTime: 100 - totalPercentage,
                focusedZone: prevState.focusedZone != null && (100 - totalPercentage) >= prevState.focusedZone[1] && (100 - totalPercentage) <= prevState.focusedZone[2] ?
                    prevState.focusedZone : null
            }));
            setError('');
            setSaveDisabled(false);
        }
        else {
            setProfile(prevState => ({
                ...prevState,
                [field]: newValue,
            }));
            setError('Sum total cannot exceed a value of 100.');
            setSaveDisabled(true);
        }
        // setSaveDisabled(value === '');
    };
    const handleSliderMarksChange = (zoneField, newZones) => {
        setProfile(prevState => ({
            ...prevState,
            [zoneField]: newZones,
        }));
    };

    return (
        <WindowContext.Consumer>
            {(context) =>
                loading ? (
                    <Loader label="Loading..." />
                ) : (
                    <Box styles={{ height: "100%", position: "relative" }}>
                        {error && (
                            <div style={{ backgroundColor: '#ffe6e6', padding: '10px', borderRadius: '4px', border: '1px solid #ff6666' }}>
                                <span style={{ color: '#ff3333', fontWeight: 'bold' }}>Error:</span> {error}
                            </div>
                        )}
                        <TextField
                            label="Profile Name"
                            value={profile.profileName}
                            onChange={(ev, newValue) => {
                                setProfile(prevState => ({
                                    ...prevState,
                                    profileName: newValue
                                }));
                            }}
                        />
                        <MultiSegmentRangeSlider
                            fieldName="meetingsTime"
                            headerText="Percentage time in meetings"
                            onSliderInputChange={(fieldName, newValue) => handleSliderInputChange(fieldName, newValue)}
                            onSliderMarksChange={(fieldName, zones) => handleSliderMarksChange('meetingsZone', zones)}
                            setValue={profile.meetingsTime}
                            defaultGreenZonePercentage={20}
                            defaultYellowZonePercentage={40}
                            setZoneBars={profile.meetingsZone}
                            rangeColors={['#FC466B', '#FFD700', '#00FF88', '#FFD700', '#FC466B']}
                            draggable={true}
                            readonly={false}
                            min={0}
                            max={100}
                        />
                        <MultiSegmentRangeSlider
                            fieldName="callsMessagesTime"
                            headerText="Percentage time in calls & messages"
                            onSliderInputChange={(fieldName, newValue) => handleSliderInputChange(fieldName, newValue)}
                            onSliderMarksChange={(fieldName, zones) => handleSliderMarksChange('callsZone', zones)}
                            setValue={profile.callsMessagesTime}
                            defaultGreenZonePercentage={20}
                            defaultYellowZonePercentage={40}
                            setZoneBars={profile.callsZone}
                            rangeColors={['#FC466B', '#FFD700', '#00FF88', '#FFD700', '#FC466B']}
                            draggable={true}
                            readonly={false}
                            min={0}
                            max={70}
                        />
                        <Flex fluid vAlign='center' gap="gap.small">
                            <Text content="Percentage time for focused work: "/>
                            <Text weight='bold' content={profile.focusedTime} />
                        </Flex>
                        <MultiSegmentRangeSlider
                            show={false}
                            fieldName="focusedTime"
                            headerText="Percentage time in focus"
                            onSliderInputChange={(fieldName, newValue) => handleSliderInputChange(fieldName, newValue)}
                            onSliderMarksChange={(fieldName, zones) => handleSliderMarksChange('focusedZone', zones)}
                            setValue={profile.focusedTime}
                            defaultGreenZonePercentage={20}
                            defaultYellowZonePercentage={40}
                            setZoneBars={profile.focusedZone}
                            rangeColors={['#FC466B', '#FFD700', '#00FF88', '#FFD700', '#FC466B']}
                            draggable={false}
                            readonly={true}
                            min={0}
                            max={100}
                        />
                        {/* <TextField
                     label="Meetings Time Percentage"
                     value={profile.meetingsTime}
                     onChange={(ev, newValue) => handleInputChange('meetingsTime', newValue)}
                     type="number"
                     min="0"
                     max="100"
                     step="1"
                 />
                 <TextField
                     label="Calls & Messages Time Percentage"
                     value={profile.callsMessagesTime}
                     onChange={(ev, newValue) => handleInputChange('callsMessagesTime', newValue)}
                     type="number"
                     min="0"
                     max="100"
                     step="1"
                 />
                 <TextField
                     label="Focused Time Percentage"
                     value={
                         profile.callsMessagesTime !== null && profile.meetingsTime !== null
                             ? 100 - (parseFloat(profile.callsMessagesTime) + parseFloat(profile.meetingsTime))
                             : profile.callsMessagesTime !== null
                                 ? 100 - parseFloat(profile.callsMessagesTime)
                                 : profile.meetingsTime !== null
                                     ? 100 - parseFloat(profile.meetingsTime)
                                     : 100
                     }
                     InputProps={{ readOnly: true }}
                     type="number"
                 /> */}
                        <Flex space="between" styles={{ marginTop: "0.6rem" }}>
                            <Box />
                            <Flex gap="gap.small" className='align-items-center'>
                                <Button content="Cancel" onClick={() => onClose(false)} />
                                <Button content="Save" type="submit" onClick={() => onSave()} primary disabled={saveDisabled} />
                            </Flex>
                        </Flex>
                    </Box>
                )
            }
        </WindowContext.Consumer>
        // <FluentDialog hidden={!showDialog} dialogContentProps={dialogContentProps} modalProps={modalProps}>
        //     <Stack tokens={{ childrenGap: 15 }}>
        //         <h2>{isEditMode ? 'Edit Profile' : 'Add Profile'}</h2>
        //         {error && (
        //             <div style={{ backgroundColor: '#ffe6e6', padding: '10px', borderRadius: '4px', border: '1px solid #ff6666' }}>
        //                 <span style={{ color: '#ff3333', fontWeight: 'bold' }}>Error:</span> {error}
        //             </div>
        //         )}
        //         <TextField
        //             label="Profile Name"
        //             value={profile.profileName}
        //             onChange={(ev, newValue) => handleInputChange('profileName', newValue)}
        //         />
        //         <MultiSegmentRangeSlider
        //             field="meetingsTime"
        //             headerText="Percentage time in meetings"
        //             setValue={50}
        //             defaultGreenZonePercentage={20}
        //             defaultYellowZonePercentage={40}
        //             setZoneBars={[20, 40, 60, 80]}
        //             rangeColors={['#FC466B', '#FFD700', '#00FF88', '#FFD700', '#FC466B']}
        //             />
        //         <TextField
        //             label="Meetings Time Percentage"
        //             value={profile.meetingsTime}
        //             onChange={(ev, newValue) => handleInputChange('meetingsTime', newValue)}
        //             type="number"
        //             min="0"
        //             max="100"
        //             step="1"
        //         />
        //         <TextField
        //             label="Calls & Messages Time Percentage"
        //             value={profile.callsMessagesTime}
        //             onChange={(ev, newValue) => handleInputChange('callsMessagesTime', newValue)}
        //             type="number"
        //             min="0"
        //             max="100"
        //             step="1"
        //         />
        //         <TextField
        //             label="Focused Time Percentage"
        //             value={
        //                 profile.callsMessagesTime !== null && profile.meetingsTime !== null
        //                     ? 100 - (parseFloat(profile.callsMessagesTime) + parseFloat(profile.meetingsTime))
        //                     : profile.callsMessagesTime !== null
        //                         ? 100 - parseFloat(profile.callsMessagesTime)
        //                         : profile.meetingsTime !== null
        //                             ? 100 - parseFloat(profile.meetingsTime)
        //                             : 100
        //             }
        //             InputProps={{ readOnly: true }}
        //             type="number"
        //         />

        //     </Stack>
        //     <DialogFooter>
        //         <Button primary content="Save" onClick={onSave} disabled={saveDisabled} />
        //         <Button content="Cancel" onClick={() => onClose(false)} />
        //     </DialogFooter>
        // </FluentDialog>
    );
}

const DeleteConfirmationDialog = ({ showDialog, profile, handleDeleteProfile, onClose, allProfile }) => {

    const dialogStyles = { main: { maxWidth: 450, padding: '20px' } };
    const dragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        keepInBounds: true,
    };

    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Delete Profile',
        closeButtonAriaLabel: 'Close',
    };
    const labelId = useId('dialogLabel');
    const subTextId = useId('subTextLabel');

    const [selectedProfile, setSelectedProfile] = useState('');
    const filteredProfiles = allProfile.filter(p => p.id !== profile.id);

    const handleChange = (event, { value }) => {
        setSelectedProfile(value);
    };

    const profileOptions = filteredProfiles.map((p) => ({
        key: p.id,
        header: p.profileName,
    }));
    const modalProps = useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
            dragOptions: dragOptions,
        }),
        [labelId, subTextId],
    );
    const onDelete = () => {
        handleDeleteProfile(selectedProfile ? selectedProfile.key : -1);
        onClose(false);
    };

    const handleCancel = () => {
        onClose(false);
    };

    return (
        <FluentDialog hidden={!showDialog} onDismiss={handleCancel} dialogContentProps={dialogContentProps} modalProps={{ modalProps, styles: dialogStyles }}>
            <Flex column gap="gap.medium">
                <Text content={`Are you sure you want to delete the profile `} />
                <Text weight="bold" content={`${profile.profileName}`} />

                {
                    profile.assignedTo != null && Number(profile.assignedTo) > 0 ?
                        <>
                            <Text content={`This profile is associated with `} />
                            <Text weight="bold" content={`${profile.assignedTo} Users`} />
                            <Text content={`Please select an alternative profile for assigned users:`} />
                            <Dropdown
                                placeholder="Select a profile"
                                items={profileOptions}
                                value={selectedProfile}
                                onChange={handleChange}
                                fluid
                            />
                        </>
                        : <></>
                }
            </Flex>
            <DialogFooter>
                {(profile.assignedTo != null && Number(profile.assignedTo) > 0 && selectedProfile) || (profile.assignedTo != null && Number(profile.assignedTo) == 0) ? <Button primary content="Delete" onClick={onDelete} /> : <Button primary content="Delete" onClick={onDelete} disabled={true} />}
                <Button content="Cancel" onClick={handleCancel} />
            </DialogFooter>
        </FluentDialog>
    );
}

export default CommunicationProfile;