import React, { useEffect, useState } from "react";
import { List, Divider } from "@fluentui/react-northstar";
import { HorizontalRuleIcon, CallDialpadIcon } from "@fluentui/react-icons-northstar";

import "./dashboard.scss";

const CardCategories = (props) => {
  const items = [
    {
      key: "isAll",
      media: <HorizontalRuleIcon size="smaller" />,
      header: "All",
      hide: false,
      dividerSection: 0
    },
    {
      key: "isProductivity",
      media: <i className={"analytics-productivity"} />,
      header: "People Engagement",
      hide: false,
      dividerSection: 0
    },
    {
      key: "isCustomerExperience",
      media: <i className={"analytics-customerExperience"} />,
      header: "One-to-One Engagement",
      hide: false,
      dividerSection: 0
    },
    {
      key: "isWellness",
      media: <i className={"analytics-wellbeing"} />,
      header: "Wellbeing",
      hide: false,
      dividerSection: 0
    },
    {
      key: "isTeamsCalling",
      media: <i className={"analytics-headphones"} />,
      header: "External Engagement",
      hide: process.env.REACT_APP_LIMITED_APP_SCOPE_FOR_MS_TESTING === 'true',
      dividerSection: 0
    },
    {
      key: "1",
      media: <i className={"analytics-summary"} />,
      header: "Summary",
      hide: false,
      dividerSection: 1
    },
    {
      key: "2",
      media: <i className={"analytics-activity2"} />,
      header: "Activity",
      hide: process.env.REACT_APP_LIMITED_APP_SCOPE_FOR_MS_TESTING === 'true',
      dividerSection: 1
    },
    {
      key: "3",
      media: <i className={"analytics-trends"} />,
      header: "Trends",
      hide: false,
      dividerSection: 1
    },
    {
      key: "4",
      media: <i className={"analytics-comparison2"} />,
      header: "Comparison",
      hide: false,
      dividerSection: 1
    },
    // {
    //     key: "5",
    //     media: <i className={"analytics-insights"} />,
    //     header: "Insights",
    // },
    {
      key: "2",
      media: <i className={"analytics-meetings"} />,
      header: "Meetings",
      hide: process.env.REACT_APP_LIMITED_APP_SCOPE_FOR_MS_TESTING === 'true',
      dividerSection: 2
    },
    {
      key: "1",
      media: <i className={"analytics-calls"} />,
      header: "Calls",
      hide: process.env.REACT_APP_LIMITED_APP_SCOPE_FOR_MS_TESTING === 'true',
      dividerSection: 2
    },
    {
      key: "3",
      media: <i className={"analytics-messages"} />,
      header: "Messages",
      hide: process.env.REACT_APP_LIMITED_APP_SCOPE_FOR_MS_TESTING === 'true',
      dividerSection: 2
    },
    {
      key: "4",
      media: <i className={"analytics-collaboration"} />,
      header: "Collaboration",
      hide: process.env.REACT_APP_LIMITED_APP_SCOPE_FOR_MS_TESTING === 'true',
      dividerSection: 2
    },
    {
      key: "5",
      media: <i className={"analytics-teamsChannels"} />,
      header: "Teams and Channels",
      hide: process.env.REACT_APP_LIMITED_APP_SCOPE_FOR_MS_TESTING === 'true',
      dividerSection: 2
    },
  ];

    return (
        <>
            <Divider important content="Engagement" size={2} />
            <List
                className="categorylist"
                selectable
                selectedIndex={props.selectedCategory}
                onSelectedIndexChange={(e, newProps) => {
                    props.handleCategoryChange(
                        newProps.selectedIndex,
                        newProps.items[newProps.selectedIndex].key,
                        1
                    );
                }}
                items={items.filter(f => f.dividerSection == 0 && f.hide == false).map(({ key, media, header }) => ({key, media, header}))}
                style={{ width: "100%" }}
            />
            <Divider important content="Types" size={2} />
            <List
                className="categorylist"
                selectable
                selectedIndex={props.selectedCategory - items.filter(f => f.dividerSection == 0 && f.hide == false).length}
                onSelectedIndexChange={(e, newProps) => {
                    props.handleCategoryChange(
                        newProps.selectedIndex + items.filter(f => f.dividerSection == 0 && f.hide == false).length,
                        "categoryTypeID",
                        Number(newProps.items[newProps.selectedIndex].key)
                    );
                }}
                items={items.filter(f => f.dividerSection == 1 && f.hide == false).map(({ key, media, header }) => ({ key, media, header }))}
                style={{ width: "100%" }}
            />
            {
                process.env.REACT_APP_LIMITED_APP_SCOPE_FOR_MS_TESTING === 'true' ? <></>
                    :
                    <>
                        <Divider important content="Activity" size={2} />
                        <List
                            className="categorylist"
                            selectable
                            selectedIndex={props.selectedCategory - items.filter(f => f.dividerSection != 2 && f.hide == false).length}
                            onSelectedIndexChange={(e, newProps) => {
                                props.handleCategoryChange(
                                    newProps.selectedIndex + items.filter(f => f.dividerSection != 2 && f.hide == false).length,
                                    "collaborationTypeID",
                                    Number(newProps.items[newProps.selectedIndex].key)
                                );
                            }}
                            items={items.filter(f => f.dividerSection == 2 && f.hide == false).map(({ key, media, header }) => ({ key, media, header }))}
                            style={{ width: "100%" }}
                        />
                    </>
            }
        </>

  );
};

export default CardCategories;
